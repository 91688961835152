import React from 'react';
import PropTypes from 'prop-types';
import AnswerButton from './AnswerButton';
import { useState } from '../../../State';


function AnswerButtons(props) {
  const {
    question,
    colors,
  } = props;

  const [{ snippets }] = useState();

  const answerLevels = [
    { level: 1, title: snippets['1384069'] },
    { level: 2, title: snippets['1384070'] },
    { level: 3, title: snippets['1384071'] },
    { level: 4, title: snippets['1384074'] },
    { level: 5, title: snippets['1384075'] },
  ];

  return (
    <div>
      {
        answerLevels.map(answerLevel => (
          <AnswerButton
            key={answerLevel.level}
            colors={colors}
            answerLevel={answerLevel}
            question={question}
          />
        ))
      }
    </div>
  );
}

AnswerButtons.defaultProps = {
  colors: true,
};

AnswerButtons.propTypes = {
  question: PropTypes.object.isRequired,
  colors: PropTypes.bool,
};

export default AnswerButtons;
