import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isFuture from 'date-fns/isFuture';
import format from 'date-fns/format';
import HabitModal from './HabitModal';
import { useState } from '../../../State';


function Habit(props) {
  const {
    habit,
  } = props;

  const [{
    locale,
    userAnswers,
    urlParams,
  }] = useState();

  const { cl, h } = urlParams;

  const {
    title,
  } = habit;

  const [showModal, setShowModal] = React.useState(false);
  const modal = React.useRef();

  const answer = userAnswers
    && userAnswers[habit.id]
    ? userAnswers[habit.id]
    : null;


  React.useEffect(() => {
    const handleClick = (e) => {
      if (modal.current.contains(e.target)) {
        return;
      }
      setShowModal(false);
    };

    if (showModal) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [showModal]);

  return (
    <>
      <Link
        className="mb-2 w-full block items-center rounded-lg p-2 text-left leading-none border-2 border-gray-300 hover:border-gray-400 focus:outline-none flex"
        to={
          cl
            ? `/${locale}/cl/${cl}/h/${habit.id}`
            : `/${locale}/h/${habit.id}`
        }
      >
        {
          !!answer
          && !answer.hidden
          && (
            (
              answer.level
              && answer.level === 1
            )
            || !answer.level
          )
          && (
            <i className="mr-2 fas fa-clipboard fa-fw text-gray-500" />
          )
        }
        {
          !!answer
          && !answer.hidden
          && answer.level
          && answer.level === 2
          && (
            <span className="flex flex-shrink-0 mr-2">
              <i className="fas fa-mountain fa-fw text-indigo-500" />
              {
                !!answer.deadline
                && !!isFuture(answer.deadline)
                && (
                  <span className="p-1 ml-2 bg-gray-200 rounded text-xs">
                    { format(answer.deadline, 'd MMM') }
                  </span>
                )
              }
              {
                !!answer.deadline
                && !isFuture(answer.deadline)
                && (
                  <span className="p-1 ml-2 bg-red-200 rounded text-xs">
                    { format(answer.deadline, 'd MMM') }
                  </span>
                )
              }
            </span>
          )
        }
        {
          !!answer
          && !answer.hidden
          && answer.level
          && answer.level === 3
          && (
            <i className="mr-2 fas fa-check fa-fw text-green-500" />
          )
        }
        { title }
      </Link>

      {
        !!h
        && h === habit.id
        && (
          <HabitModal
            key={habit.id}
            habit={habit}
          />
        )
      }
    </>
  );
}

Habit.propTypes = {
  habit: PropTypes.object.isRequired,
};

export default Habit;
