import React from 'react';
import { login, loginAnonymously } from '../database/auth';
import { useState } from '../State';


function Home() {
  const [{ snippets }] = useState();

  return (
    <div className="max-w-md mx-auto mt-32 text-center">
      <h1 className="text-indigo-500 font-bold text-2xl mb-5">
        Focus Academy Canvas
      </h1>

      <div className="max-w-sm mx-auto mt-10 leading-normal">
        <button
          type="button"
          className="mx-auto block mt-10 bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded focus:outline-none"
          onClick={() => { login(); }}
        >
          { snippets['1383930'] }
          <i className="ml-2 fas fa-arrow-right" />
        </button>
        <button
          className="mx-auto block mt-5 border-2 hover:border-indigo-600 text-gray-600 py-2 px-4 rounded focus:outline-none"
          type="button"
          onClick={() => {
            loginAnonymously();
          }}
        >
          { snippets['1383931'] }
          <i className="hidden ml-2 fas fa-arrow-right" />
        </button>
      </div>
    </div>
  );
}

export default Home;
