import React from 'react';
import PropTypes from 'prop-types';
import { useState } from '../../../State';

function IntroductionModal(props) {
  const {
    introductionVideo,
  } = props;

  const [{
    snippets,
  }] = useState();

  const [showModal, setShowModal] = React.useState(false);
  const modal = React.useRef();

  React.useEffect(() => {
    const handleClick = (e) => {
      if (modal.current.contains(e.target)) {
        return;
      }
      setShowModal(false);
    };

    if (showModal) {
      document.body.classList.add('overflow-hidden');
      document.addEventListener('mousedown', handleClick);
      document.addEventListener('touchend', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchend', handleClick);
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchend', handleClick);
    };
  }, [showModal]);


  return (
    <>
      <button
        className="flex-no-shrink text-base cursor-pointer border-2 p-1 lg:p-2 hover:border-green-500 rounded-lg font-normal leading-none focus:outline-none text-base"
        type="button"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <i className="mr-1 lg:mr-2 fas fa-play-circle flex-no-shrink" />
        <span>{ snippets['1384014'] }</span>
      </button>

      {
        !!showModal
        && (
          <div
            className="fixed inset-0 z-50 flex py-10 px-5 lg:px-0"
            style={{
              background: 'rgba(0,0,0,0.7)',
              overflowY: 'scroll',
              overflowScrolling: 'touch',
              WebkitOverflowScrolling: 'touch',
              transform: 'translate3d(0,0,0)',
              WebkitTransform: 'translate3d(0,0,0)',
            }}
          >
            <div
              ref={modal}
              className="relative p-5 bg-white w-full max-w-3xl m-auto flex-col flex rounded-lg shadow-lg"
            >
              <div className="flex items-start mb-5">
                <button
                  type="button"
                  className="ml-auto leading-none p-2 inline-block rounded-lg hover:bg-gray-300 focus:outline-none"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <i className="fas fa-times fa-fw" />
                </button>
              </div>
              <div
                className="embed-responsive aspect-ratio-16-9"
                dangerouslySetInnerHTML={{
                  __html: `<iframe class="embed-responsive-item" src="https://player.vimeo.com/video/${introductionVideo}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
                }}
              />
            </div>
          </div>
        )
      }
    </>
  );
}

IntroductionModal.propTypes = {
  introductionVideo: PropTypes.string.isRequired,
};

export default IntroductionModal;
