import * as firebase from 'firebase/app';
import 'firebase/firestore';

export const config = {
  apiKey: 'AIzaSyBykTnQEya1e3wpJiD1Q4xCWiwScdn3Ujk',
  authDomain: 'focus-academy-online.firebaseapp.com',
  databaseURL: 'https://focus-academy-online.firebaseio.com',
  projectId: 'focus-academy-online',
  storageBucket: 'focus-academy-online.appspot.com',
  messagingSenderId: '369008171960',
  appId: '1:369008171960:web:8bf9e53068c66921',
};

firebase.initializeApp(config);

export const db = firebase.firestore();
