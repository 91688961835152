import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { useState } from '../State';
import Logo from '../images/logo.png';


function Footer() {
  const [{
    snippets,
  }] = useState();

  return (
    <div className="bg-gray-400 text-gray-700 p-10 mt-32 pb-32 lg:pb-10">
      <div className="max-w-5xl mx-auto lg:flex justify-between items-center">
        <div className="mx-auto w-2/3 lg:w-1/5">
          <img src={Logo} alt="Focus Academy Logo" />
        </div>
        <div className="mx-auto w-2/3 lg:w-2/5 text-center mt-10 lg:mt-0">
          <a
            className="text-left leading-none hover:underline focus:outline-none"
            target="_blank"
            rel="noopener noreferrer"
            href={snippets['1388224']}
          >
            { snippets['1384001'] }
            <i className="ml-3 fas fa-arrow-right" />
          </a>
        </div>
        <div className="mx-auto w-2/3 lg:w-1/5 text-xs mt-10 lg:mt-0 text-center lg:text-left">
          <ReactMarkdown
            className="markdown"
            source={snippets['1490127']}
            escapeHtml={false}
            linkTarget="_blank"
          />
        </div>
      </div>
    </div>
  );
}


export default Footer;
