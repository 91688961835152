import React from 'react';
import { sortBy, indexOf, find } from 'underscore';
import clJson from '../../cl.json';
import Habit from './CL/Habit';
import { useState } from '../../State';


function Starred() {
  const [{
    locale,
    snippets,
    userAnswers,
  }] = useState();

  const clBlocks = clJson[locale];

  const userAnswersArray = userAnswers && Object.keys(userAnswers)
    ? Object.keys(userAnswers)
    : null;

  const starredTechniques = userAnswers
    && Object.keys(userAnswers)
    && Object.keys(userAnswers).length
    ? Object.keys(userAnswers).filter(
      answer => !!userAnswers[answer] && !userAnswers[answer].hidden,
    )
    : null;

  // Get all habits from all cls.
  let habits = [];
  clBlocks.forEach((block) => {
    habits = [...habits, ...block.habits];
  });

  const order = [2, 0, 1, 3];
  let habitList = [];
  if (starredTechniques && habits) {
    habitList = habits
      .filter(habit => (
        // return if habit.id is included in starredTechniques
        find(userAnswersArray, userAnswerKey => (
          userAnswerKey === habit.id
          && userAnswers[userAnswerKey]
          && !userAnswers[userAnswerKey].hidden
        ))
      ))
      .map((habit) => {
        const answer = userAnswers && userAnswers[habit.id] ? userAnswers[habit.id] : null;
        const { level } = answer && answer.level ? answer : { level: 0 };
        return {
          ...habit,
          answer,
          index: indexOf(order, level),
        };
      });
  }


  return (
    <div className="bg-white rounded-lg p-5">
      <h3 className="text-xl font-bold mb-3">{ snippets['1383999'] }</h3>
      {
        !!habitList
        && sortBy(habitList, 'index').map((habit) => {
          if (!habit) {
            return null;
          }

          return (
            <Habit
              key={habit.id}
              habit={habit}
            />
          );
        })
      }
      {
        (
          !starredTechniques || !starredTechniques.length
        )
        && (
          <div className="border-2 border-dashed border-gray-100 rounded-lg p-2 text-gray-400">
            { snippets['1384000'] }
          </div>
        )
      }
    </div>
  );
}

export default Starred;
