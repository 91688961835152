import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Nav from './components/Dashboard/Nav';
import { useState } from './State';
import Intake from './components/Intake';


function Page(props) {
  const [state, dispatch] = useState();
  const { match, auth, intake } = props;
  const { params } = match;

  const { userAnswers } = state;


  // update state if url params changed
  if (!isEqual(params, state.urlParams)) {
    dispatch({
      type: 'changeUrlParams',
      newUrlParams: params,
    });
  }

  // update state if auth changed
  if (!isEqual(auth, state.auth)) {
    dispatch({
      type: 'changeAuth',
      newAuth: auth,
    });
    dispatch({
      type: 'changeUser',
      newUser: auth.user,
    });
    dispatch({
      type: 'changeUserAnswers',
      newUserAnswers: userAnswers ? { ...auth.userAnswers, ...userAnswers } : auth.userAnswers,
      user: auth.user || null,
    });
  }


  if (auth.initializing) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  if (!state.user && !state.anonymous) {
    return (
      <Home locale={state.locale} />
    );
  }

  if (intake) {
    return (
      <>
        <Nav />
        <div className="px-5 p lg:p-0">
          <Intake />
        </div>
        <div className="mt-32" />
      </>
    );
  }

  return (
    <>
      <Nav />
      <Dashboard intake={intake} />
    </>
  );

  /*
  const {
    locale,
    cl,
    q,
    h,
  } = match.params;

  return (
    <localeContext.Provider value={{ locale }}>
      <userContext.Provider value={{ user: auth.user || { anonymous: auth.anonymous } }}>
        <Nav cl={cl} q={q} h={h} />
        <Dashboard
          answers={auth.answers}
          setAnswers={auth.setAnswers}
          cl={cl}
          q={q}
          h={h}
          intake={intake}
        />
      </userContext.Provider>
    </localeContext.Provider>
  );
  */
}

Page.defaultProps = {
  intake: false,
};

Page.propTypes = {
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  intake: PropTypes.bool,
};

export default Page;
