import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';


const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const user = firebase.auth().currentUser;
    return {
      initializing: !user,
      user,
    };
  });

  function onChange(user) {
    if (!user) {
      setState({
        initializing: false,
        user,
      });
      return;
    }
    setState({
      initializing: false,
      user,
    });
    if (user && user.uid) {
      firebase
        .firestore()
        .collection('answers')
        .doc(user.uid)
        .get()
        .then((doc) => {
          console.count();
          console.log('answers');
          const data = doc.data();
          if (!data || !data.answers) {
            return;
          }
          setState({
            initializing: false,
            user,
            userAnswers: data.answers,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  return state;
};

export default useAuth;
