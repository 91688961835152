import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReactMarkdown from 'react-markdown/with-html';
import HabitJourney from './HabitJourney';
import { useState } from '../../../State';


function HabitModal(props) {
  const [{
    locale,
    userAnswers,
    urlParams,
    user,
    snippets,
  }, dispatch] = useState();

  const { cl } = urlParams;

  const {
    habit,
  } = props;

  const answer = userAnswers
    && userAnswers[habit.id]
    ? userAnswers[habit.id]
    : null;

  const {
    title,
  } = habit;
  const [showModal, setShowModal] = React.useState(true);
  const modal = React.useRef();


  React.useEffect(() => {
    const handleClick = (e) => {
      if (modal.current.contains(e.target)) {
        return;
      }
      setShowModal(false);
    };

    if (showModal) {
      document.body.classList.add('overflow-hidden');
      document.addEventListener('mousedown', handleClick);
      document.addEventListener('touchend', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchend', handleClick);
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchend', handleClick);
    };
  }, [showModal]);

  if (!showModal) {
    return (
      <Redirect
        to={
          cl ? `/${locale}/cl/${cl}` : `/${locale}`
        }
      />
    );
  }

  return (
    <div
      className="fixed inset-0 z-50 flex py-10 px-5 lg:px-0"
      style={{
        background: 'rgba(0,0,0,0.7)',
        overflowY: 'scroll',
        overflowScrolling: 'touch',
        WebkitOverflowScrolling: 'touch',
        transform: 'translate3d(0,0,0)',
        WebkitTransform: 'translate3d(0,0,0)',
      }}
    >
      <div
        ref={modal}
        className="relative p-5 bg-white w-full max-w-3xl m-auto flex-col flex rounded-lg shadow-lg flex-start"
      >
        <div className="flex items-start mb-5">
          <h2 className="font-bold text-2xl">{ title }</h2>
          <button
            type="button"
            className="ml-auto leading-none p-2 inline-block rounded-lg hover:bg-gray-300 focus:outline-none"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <i className="fas fa-times fa-fw" />
          </button>
        </div>
        {
          (
            !answer
            || answer.hidden
          )
          && (
            <div className="border-2 border-gray-300 border-dashed p-5 rounded-lg">
              <button
                className="mr-auto block bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded focus:outline-none focus:outline-none flex items-bottom"
                type="button"
                onClick={() => {
                  dispatch({
                    type: 'changeUserAnswers',
                    newUserAnswers: {
                      ...userAnswers,
                      [habit.id]: {
                        ...answer,
                        hidden: false,
                      },
                    },
                    user,
                  });
                }}
              >
                <i className="mr-2 fas fa-plus leading-none" />
                <span className="flex-grow leading-none">{ snippets['1405254'] }</span>
              </button>
            </div>
          )
        }
        {
          !!answer
          && !answer.hidden
          && (
            <HabitJourney habit={habit} />
          )
        }
        {
          !!answer
          && !answer.hidden
          && (
            <button
              className="mb-2 self-start rounded-lg p-2 text-left leading-none focus:outline-none hover:bg-gray-200 text-sm text-gray-600"
              type="button"
              onClick={() => {
                dispatch({
                  type: 'changeUserAnswers',
                  newUserAnswers: {
                    ...userAnswers,
                    [habit.id]: {
                      ...answer,
                      hidden: true,
                    },
                  },
                  user,
                });
              }}
            >
              <i className="mr-2 fas fa-trash text-gray-400" />
              <span>{ snippets['1405256'] }</span>
            </button>
          )
        }
        <div className="mt-10">
          <ReactMarkdown
            className="markdown article"
            source={habit.content}
            escapeHtml={false}
            linkTarget="_blank"
          />
        </div>
      </div>
    </div>
  );
}

HabitModal.propTypes = {
  habit: PropTypes.object.isRequired,
};

export default HabitModal;
