import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import formatDistance from 'date-fns/formatDistance';
import isFuture from 'date-fns/isFuture';
import ReactMarkdown from 'react-markdown';
import { useState } from '../../../../State';

registerLocale('nl', nl);


function Level2(props) {
  const {
    habit,
  } = props;

  const [{
    userAnswers,
    user,
    locale,
    snippets,
  }, dispatch] = useState();

  const answer = userAnswers
    && userAnswers[habit.id]
    ? userAnswers[habit.id]
    : null;

  const [newDate, setNewDate] = React.useState(
    !!answer
    && !!answer.deadline
      ? answer.deadline
      : false,
  );

  const [evaluationSuccess, setEvaluationSuccess] = React.useState(false);

  setDefaultLocale(locale);

  return (
    <>
      <div>
        {
          !answer.deadline
          && (
            <div className="">
              <div className="mb-2 font-bold">{snippets['1405277']}</div>
              <div className="datepicker-full">
                <DatePicker
                  className="block w-full border-2 rounded-lg px-2 py-1 focus:outline-none"
                  selected={newDate}
                  dateFormat={
                    locale === 'nl'
                      ? 'iiii d MMMM yyyy'
                      : 'iiii, MMMM d, yyyy'
                  }
                  locale={locale}
                  onChange={(DatePickerData) => {
                    setNewDate(DatePickerData.getTime());
                  }}
                  placeholder="test"
                />
              </div>
              <div className="flex">
                {
                  !!newDate
                  && isFuture(newDate)
                  && (
                    <div className="p-2 text-sm">
                      {'deadline in '}
                      { formatDistance(new Date(), newDate, { locale: locale === 'nl' ? nl : null }) }
                    </div>
                  )
                }
                <button
                  type="button"
                  className={
                    newDate
                      ? 'ml-auto mt-2 block bg-indigo-500 hover:bg-indigo-600 text-white py-1 px-2 rounded focus:outline-none focus:outline-none'
                      : 'ml-auto mt-2 block bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded focus:outline-none focus:outline-none'
                  }
                  onClick={() => {
                    setNewDate(null);
                    const newAnswer = {
                      ...answer,
                      deadline: newDate,
                    };
                    dispatch({
                      type: 'changeUserAnswers',
                      newUserAnswers: {
                        ...userAnswers,
                        [habit.id]: newAnswer,
                      },
                      user,
                    });
                  }}
                >
                  { snippets['1405280'] }
                </button>
              </div>
            </div>
          )
        }
        {
          !!answer.deadline
          && isFuture(answer.deadline)
          // in the future
          && (
            <div className="">
              <div className="font-bold">
                { snippets['1405284'] }
                { formatDistance(new Date(), answer.deadline, { locale: locale === 'nl' ? nl : null }) }
              </div>
              <div className="mt-5"><ReactMarkdown className="markdown" source={snippets['1405292']} /></div>
              <button
                type="button"
                className="mt-10 block bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded focus:outline-none focus:outline-none"
                onClick={() => {
                  setNewDate(null);
                  const newAnswer = {
                    ...answer,
                    deadline: null,
                  };
                  dispatch({
                    type: 'changeUserAnswers',
                    newUserAnswers: {
                      ...userAnswers,
                      [habit.id]: newAnswer,
                    },
                    user,
                  });
                }}
              >
                { snippets['1405309'] }
              </button>
            </div>
          )
        }

        {
          !!answer.deadline
          && !isFuture(answer.deadline)
          && (
            <div>
              {
                !evaluationSuccess
                && (
                  <div className="">
                    <ReactMarkdown className="markdown" source={snippets['1405395']} />
                    <div className="mt-5 flex">
                      <button
                        type="button"
                        className="mr-5 block bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded focus:outline-none focus:outline-none"
                        onClick={() => {
                          setEvaluationSuccess('no');
                        }}
                      >
                        { snippets['1405409'] }
                      </button>
                      <button
                        type="button"
                        className="block bg-indigo-500 hover:bg-indigo-600 text-white py-1 px-4 rounded focus:outline-none focus:outline-none"
                        onClick={() => {
                          setNewDate(null);
                          setEvaluationSuccess(null);
                          const newAnswer = {
                            ...answer,
                            level: 3,
                            deadline: null,
                          };
                          dispatch({
                            type: 'changeUserAnswers',
                            newUserAnswers: {
                              ...userAnswers,
                              [habit.id]: newAnswer,
                            },
                            user,
                          });
                        }}
                      >
                        { snippets['1405410'] }
                        <i className="ml-2 fas fa-arrow-right leading-none" />
                      </button>
                    </div>
                  </div>
                )
              }

              {
                !!evaluationSuccess
                && evaluationSuccess === 'no'
                && (
                  <div className="">
                    <div className="mb-5">
                      <ReactMarkdown className="markdown" source={snippets['1405418']} />
                    </div>
                    <button
                      type="button"
                      className="mt-3 block bg-indigo-500 hover:bg-indigo-600 text-white py-1 px-2 rounded focus:outline-none focus:outline-none"
                      onClick={() => {
                        setNewDate(null);
                        setEvaluationSuccess(null);
                        const newAnswer = {
                          ...answer,
                          deadline: null,
                        };
                        dispatch({
                          type: 'changeUserAnswers',
                          newUserAnswers: {
                            ...userAnswers,
                            [habit.id]: newAnswer,
                          },
                          user,
                        });
                      }}
                    >
                      <i className="mr-2 fas fa-redo leading-none" />
                      { snippets['1405424'] }
                    </button>
                    <button
                      type="button"
                      className="mt-3 block bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded focus:outline-none focus:outline-none"
                      onClick={() => {
                        setNewDate(null);
                        setEvaluationSuccess(null);
                        const newAnswer = {
                          ...answer,
                          level: 1,
                          deadline: null,
                        };
                        dispatch({
                          type: 'changeUserAnswers',
                          newUserAnswers: {
                            ...userAnswers,
                            [habit.id]: newAnswer,
                          },
                          user,
                        });
                      }}
                    >
                      <i className="mr-2 fas fa-arrow-left leading-none" />
                      { snippets['1405427'] }
                    </button>
                    <button
                      className="mt-3 block rounded-lg p-2 text-left leading-none focus:outline-none hover:bg-gray-200 text-sm text-gray-600"
                      type="button"
                      onClick={() => {
                        dispatch({
                          type: 'changeUserAnswers',
                          newUserAnswers: {
                            ...userAnswers,
                            [habit.id]: {
                              ...answer,
                              level: 1,
                              deadline: null,
                              hidden: true,
                            },
                          },
                          user,
                        });
                      }}
                    >
                      <i className="mr-2 fas fa-trash text-gray-400" />
                      { snippets['1405256'] }
                    </button>
                  </div>
                )
              }
            </div>
          )
        }
      </div>

      {
        !answer.deadline
        && (
          <div className="mt-20">
            <button
              className="hidden border-2 border-gray-200 hover:bg-gray-200 text-gray-600 py-2 px-4 rounded focus:outline-none focus:outline-none flex items-bottom"
              type="button"
              onClick={() => {
                const newAnswer = {
                  ...answer,
                  level: 1,
                };
                dispatch({
                  type: 'changeUserAnswers',
                  newUserAnswers: {
                    ...userAnswers,
                    [habit.id]: newAnswer,
                  },
                  user,
                });
              }}
            >
              <i className="mr-2 fas fa-arrow-left leading-none" />
              <span className="flex-grow leading-none">{ snippets['1405257'] }</span>
            </button>

            <button
              className="block ml-auto border-2 border-gray-200 hover:bg-gray-200 text-gray-600 py-2 px-4 rounded focus:outline-none focus:outline-none flex items-bottom"
              type="button"
              onClick={() => {
                const newAnswer = {
                  ...answer,
                  level: 3,
                };
                dispatch({
                  type: 'changeUserAnswers',
                  newUserAnswers: {
                    ...userAnswers,
                    [habit.id]: newAnswer,
                  },
                  user,
                });
              }}
            >
              <span className="flex-grow leading-none">{ snippets['1405259'] }</span>
              <i className="ml-2 fas fa-arrow-right leading-none" />
            </button>
          </div>
        )
      }
    </>
  );
}

Level2.propTypes = {
  habit: PropTypes.object.isRequired,
};

export default Level2;
