import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import QuestionModal from './QuestionModal';
import { useState } from '../../../State';

function Question(props) {
  const {
    question,
  } = props;

  const [{
    locale,
    userAnswers,
    urlParams,
  }] = useState();

  const { cl, q } = urlParams;

  const answer = userAnswers
    && userAnswers[question.id]
    ? userAnswers[question.id]
    : null;

  const { title } = question;

  let colorStyle = 'gray';
  if (answer === 1) {
    colorStyle = 'green';
  }
  if (answer === 2) {
    colorStyle = 'yellow';
  }
  if (answer === 3) {
    colorStyle = 'orange';
  }
  if (answer === 4) {
    colorStyle = 'red';
  }
  if (answer === 5) {
    colorStyle = 'red';
  }

  return (
    <>
      <Link
        className={`mb-2 w-full block items-center rounded-lg p-2 text-left leading-none border-2 border-transparent hover:border-${colorStyle}-400 focus:outline-none flex ${!answer ? 'border-dashed border-gray-300 bg-transparent' : `bg-${colorStyle}-200`}`}
        to={`/${locale}/cl/${cl}/q/${question.id}`}
      >
        <i className={`flex-no-shrink fas fa-circle text-${colorStyle}-400 mr-2`} />
        <span className="flex-grow">
          { title }
        </span>
      </Link>

      {
        !!q
        && q === question.id
        && (
          <QuestionModal
            key={question.id}
            question={question}
          />
        )
      }

    </>
  );
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
};

export default Question;
