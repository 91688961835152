import React from 'react';
import { Link } from 'react-router-dom';
import AnswerButtons from './Dashboard/CL/AnswerButtons';
import { useState } from '../State';

function Intake() {
  const [{
    locale,
    snippets,
    clBlocks,
  }] = useState();

  return (
    <div
      className="max-w-5xl m-auto"
    >
      <Link
        className="inline-block mt-10 rounded-lg px-2 py-1 leading-none bg-gray-500 text-white hover:bg-gray-600 focus:outline-none"
        to={`/${locale}`}
      >
        <i className="fas fa-arrow-left mr-2" />
        { snippets['1383963'] }
      </Link>
      <div className="bg-white p-5 rounded-lg my-10">

        {
          !!clBlocks
          && clBlocks.map((clBlock) => {
            const {
              id,
              title,
              questions,
            } = clBlock;

            return (
              <div key={id} className="mb-16">
                <h2 className="font-bold text-xl">
                  <i className="em em-fire mr-3 flex-no-shrink" />
                  { title }
                </h2>
                <div>
                  {
                    questions.map((question) => {
                      return (
                        <div key={question.id} className="mt-10">
                          <h3 className="text-lg mb-2">{ question.title }</h3>
                          <h5 className="font-bold text-base mb-2">{ question.question }</h5>
                          <AnswerButtons colors={false} question={question} />
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            );
          })
        }
        <div className="text-right">
          <Link
            className="rounded-lg px-4 py-2 text-lg leading-none bg-green-500 text-white hover:bg-green-600 focus:outline-none"
            to={`/${locale}`}
          >
            { snippets['1383969'] }
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Intake;
