import * as firebase from 'firebase/app';
import 'firebase/auth';

const provider = new firebase.auth.GoogleAuthProvider();

export const login = () => {
  firebase.auth().signInWithPopup(provider);
};

export const loginAnonymously = () => {
  firebase.auth().signInAnonymously();
};

export const logout = () => {
  firebase.auth().signOut();
};
