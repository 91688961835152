import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { useState } from '../../../State';


function AnswerButton(props) {
  const {
    answerLevel,
    question,
    colors,
  } = props;

  const [{ userAnswers, user }, dispatch] = useState();

  const answer = userAnswers
    && userAnswers[question.id]
    ? userAnswers[question.id]
    : null;


  let colorStyle = 'blue';
  if (answerLevel.level === 1 && !!colors) {
    colorStyle = 'green';
  }
  if (answerLevel.level === 2 && !!colors) {
    colorStyle = 'yellow';
  }
  if (answerLevel.level === 3 && !!colors) {
    colorStyle = 'orange';
  }
  if (answerLevel.level === 4 && !!colors) {
    colorStyle = 'red';
  }
  if (answerLevel.level === 5 && !!colors) {
    colorStyle = 'red';
  }

  return (
    <button
      className={
        answer === answerLevel.level
          ? `mb-2 w-full block items-center rounded-lg p-2 text-left leading-none border-2 bg-${colorStyle}-200 border-${colorStyle}-300 focus:outline-none flex cursor-default text-sm lg:text-base`
          : 'mb-2 w-full block items-center rounded-lg p-2 text-left leading-none border-2 bg-gray-100 border-gray-200 hover:border-gray-400 focus:outline-none flex text-sm lg:text-base'
      }
      type="button"
      onClick={() => {
        dispatch({
          type: 'changeUserAnswers',
          newUserAnswers: {
            ...userAnswers,
            [question.id]: answerLevel.level,
          },
          user,
        });
      }}
    >
      <i
        className={answer === answerLevel.level
          ? `flex-no-shrink fas fa-circle text-${colorStyle}-400 mr-2`
          : 'flex-no-shrink fas fa-circle text-gray-200 mr-2'
        }
      />
      <span className="flex-grow">
        <Markdown>
          { answerLevel.title }
        </Markdown>
      </span>
    </button>
  );
}

AnswerButton.propTypes = {
  answerLevel: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  colors: PropTypes.bool.isRequired,
};

export default AnswerButton;
