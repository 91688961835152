import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from '../../State';


function Graph() {
  const [{
    locale,
    userAnswers,
    clBlocks,
  }] = useState();

  return (
    <div>
      {
        clBlocks.map((clBlock) => {
          const {
            id,
            title,
            iconClass,
            questions,
          } = clBlock;

          // total questions
          const totalQuestions = questions.length * 5;

          // calculate total answers
          let totalAnswers = 0;
          questions.forEach((question) => {
            if (userAnswers) {
              Object.keys(userAnswers).forEach((answer) => {
                if (question.id === answer) {
                  totalAnswers += userAnswers[answer];
                }
              });
            }
          });

          // calculate percentage
          const percentage = userAnswers && totalAnswers > 1
            ? (totalAnswers / totalQuestions * 100).toFixed(1)
            : 0;

          // calculate color
          let color = 'green';
          if (percentage > 25) {
            color = 'yellow';
          }
          if (percentage > 50) {
            color = 'orange';
          }
          if (percentage > 75) {
            color = 'red';
          }

          return (
            <Link
              className="block w-full mb-2 p-3 bg-white text-sm rounded-lg shadow border-2 border-transparent hover:border-indigo-500"
              to={`/${locale}/cl/${id}`}
              key={id}
            >
              <i className={`${iconClass} mr-2`} />
              <span className="font-bold text-lg">{ title }</span>
              <div className="w-full bg-gray-300 mt-2 mb-1">
                <div
                  className={`bg-${color}-500 h-1`}
                  style={{ width: `${percentage}%` }}
                />
              </div>
              <div>
                {
                  !!userAnswers
                  && (
                    questions.map(question => (
                      Object.keys(userAnswers).map((answer) => {
                        if (question.id === answer) {
                          if (userAnswers[answer] === 1) {
                            return (
                              <i key={question.id} className="fas fa-circle text-green-400 mr-1" />
                            );
                          }
                          if (userAnswers[answer] === 2) {
                            return (
                              <i key={question.id} className="fas fa-circle text-yellow-400 mr-1" />
                            );
                          }
                          if (userAnswers[answer] === 3) {
                            return (
                              <i key={question.id} className="fas fa-circle text-orange-400 mr-1" />
                            );
                          }
                          if (userAnswers[answer] === 4) {
                            return (
                              <i key={question.id} className="fas fa-circle text-red-400 mr-1" />
                            );
                          }
                          if (userAnswers[answer] === 5) {
                            return (
                              <i key={question.id} className="fas fa-circle text-red-400 mr-1" />
                            );
                          }
                        }
                        return null;
                      })
                    ))
                  )
                }
              </div>
            </Link>
          );
        })
      }
    </div>
  );
}


export default Graph;
