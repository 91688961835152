import React from 'react';
import {
  BrowserRouter as
  Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { StateProvider } from './State';
import Page from './Page';
import NotFound from './components/NotFound';
import snippetsJson from './snippets.json';
import clJson from './cl.json';
import useAuth from './database/useAuth';
import { updateAnswers } from './database/answers';
import Footer from './components/Footer';
import ScrollToTop from './ScrollToTop';


const App = () => {
  const auth = useAuth();

  const initialState = {
    locale: 'en',
    theme: { primary: 'green' },
    snippets: Object.assign(
      {},
      ...snippetsJson.en.map(snippet => ({ [snippet.id]: snippet.snippet })),
    ),
    clBlocks: clJson.en,
    // urlParams (cl, q, h)
    // user
    // answers
  };

  const reducer = (state, action) => {
    let locale = state.locale || 'en';

    switch (action.type) {
      case 'changeAuth':
        return {
          ...state,
          auth: action.newAuth,
        };

      case 'changeUser':
        return {
          ...state,
          user: action.newUser,
        };

      case 'changeUserAnswers':
        if (action.user && action.newUserAnswers) {
          updateAnswers({
            user: action.user,
            answers: {
              ...action.newUserAnswers,
            },
          });
        }
        return {
          ...state,
          userAnswers: action.newUserAnswers,
        };

      case 'changeUrlParams':
        locale = action.newUrlParams.locale || locale;
        return {
          ...state,
          urlParams: action.newUrlParams,
          locale,
          snippets: Object.assign(
            {},
            ...snippetsJson[locale].map(snippet => ({ [snippet.id]: snippet.snippet })),
          ),
          clBlocks: clJson[locale],
        };

      /*
      case 'setAnonymous':
        return {
          ...state,
          anonymous: true,
        };
      */

      default:
        return state;
    }
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <ScrollToTop>
          <div className="flex flex-col min-h-full">
            <div className="flex-grow">
              <Switch>
                <Route
                  path="/"
                  exact
                  component={
                    () => {
                      const browserLocale = navigator.language || navigator.userLanguage;
                      if (browserLocale === 'nl-NL') {
                        return (
                          <Redirect to="/nl" auth={auth} />
                        );
                      }
                      return (
                        <Redirect to="/en" auth={auth} />
                      );
                    }
                  }
                />
                <Route
                  path="/:locale(nl|en)"
                  exact
                  component={props => <Page {...props} auth={auth} />}
                />
                <Route
                  path="/:locale(nl|en)/intake"
                  exact
                  component={props => <Page {...props} auth={auth} intake />}
                />
                <Route
                  path="/:locale(nl|en)/cl/:cl"
                  exact
                  component={props => <Page {...props} auth={auth} />}
                />
                <Route
                  path="/:locale(nl|en)/cl/:cl/q/:q"
                  exact
                  component={props => <Page {...props} auth={auth} />}
                />
                <Route
                  path="/:locale(nl|en)/h/:h"
                  exact
                  component={props => <Page {...props} auth={auth} />}
                />
                <Route
                  path="/:locale(nl|en)/cl/:cl/h/:h"
                  exact
                  component={props => <Page {...props} auth={auth} />}
                />
                <Route
                  path="/:locale(nl|en)/c/cl/:cl/intro"
                  exact
                  component={props => <Page {...props} auth={auth} />}
                />
                <Route
                  component={props => <NotFound {...props} auth={auth} />}
                />
              </Switch>
            </div>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    </StateProvider>
  );
};

export default App;
