import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '.';


// Update
export const updateAnswers = ({ user, answers }) => {
  db.collection('answers').doc(user.uid).set({
    answers,
  }, { merge: true });
};


// Read
export function useAnswers(uid) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [answers, setAnswers] = React.useState(null);

  React.useEffect(
    () => {
      const unsubscribe = firebase
        .firestore()
        .collection('answers')
        .doc(uid)
        .get((doc) => {
          console.count();
          console.log('answers');
          const data = doc.data();
          setLoading(false);
          setAnswers({
            uid: doc.id,
            ...data,
          });
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid],
  );

  return {
    error,
    loading,
    answers,
  };
}
