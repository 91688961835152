import React from 'react';
import PropTypes from 'prop-types';
import { useState } from '../../../../State';


function Level1(props) {
  const {
    habit,
  } = props;

  const [{
    userAnswers,
    user,
    snippets,
  }, dispatch] = useState();

  const answer = userAnswers
    && userAnswers[habit.id]
    ? userAnswers[habit.id]
    : null;

  const amountOfTodos = habit && habit.todos && habit.todos.length ? habit.todos.length : 0;
  const amountOfTodosDone = answer && answer.todos
    ? Object.values(answer.todos).filter(Boolean).length
    : 0;

  return (
    <>
      <div className="border-t">
        {
          !!habit
          && !!habit.todos
          && !!habit.todos.length
          && habit.todos.map(todo => (
            <div key={todo.id}>
              <button
                className={
                  !!answer.todos
                  && answer.todos[todo.id]
                    ? 'flex w-full text-left px-2 py-2 border-b hover:bg-gray-200 line-through text-gray-500 focus:outline-none'
                    : 'flex w-full text-left px-2 py-2 border-b hover:bg-gray-200 focus:outline-none'
                }
                type="button"
                onClick={() => {
                  const newAnswer = {
                    ...answer,
                    todos: answer.todos
                      ? {
                        ...answer.todos,
                        [todo.id]: !answer.todos || !answer.todos[todo.id],
                      }
                      : {
                        [todo.id]: !answer.todos || !answer.todos[todo.id],
                      },
                  };
                  dispatch({
                    type: 'changeUserAnswers',
                    newUserAnswers: {
                      ...userAnswers,
                      [habit.id]: newAnswer,
                    },
                    user,
                  });
                }}
              >
                {
                  !!answer.todos
                  && answer.todos[todo.id]
                  && <i className="mr-2 far fa-check-square leading-tight" />
                }
                {
                  (
                    !answer.todos
                    || !answer.todos[todo.id]
                  )
                  && <i className="mr-2 far fa-square leading-tight" />
                }
                <span className="leading-tight">{todo.title}</span>
              </button>
            </div>
          ))
        }
      </div>
      <div className="mt-16">
        <button
          className={
            amountOfTodos === amountOfTodosDone
              ? 'ml-auto block border-2 border-transparent bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded focus:outline-none focus:outline-none flex items-bottom'
              : 'ml-auto block border-2 border-gray-200 hover:bg-gray-200 text-gray-600 py-2 px-4 rounded focus:outline-none focus:outline-none flex items-bottom'
          }
          type="button"
          onClick={() => {
            const newAnswer = {
              ...answer,
              level: 2,
            };
            dispatch({
              type: 'changeUserAnswers',
              newUserAnswers: {
                ...userAnswers,
                [habit.id]: newAnswer,
              },
              user,
            });
          }}
        >
          <span className="flex-grow leading-none">{ snippets['1405258'] }</span>
          <i className="ml-2 fas fa-arrow-right leading-none" />
        </button>
      </div>
    </>
  );
}

Level1.propTypes = {
  habit: PropTypes.object.isRequired,
};

export default Level1;
