import React from 'react';
import PropTypes from 'prop-types';
import { useState } from '../../../../State';
import Level1 from './Level1';
import Level2 from './Level2';
import Level3 from './Level3';


function HabitJourney(props) {
  const {
    habit,
  } = props;

  const [{
    userAnswers,
    user,
    snippets,
  }, dispatch] = useState();

  const answer = userAnswers
    && userAnswers[habit.id]
    ? userAnswers[habit.id]
    : null;

  const { level } = answer;

  return (
    <>
      <div className="mb-3">

        <div>
          <button
            type="button"
            className={
              !level || level === 1
                ? 'block w-full bg-indigo-500 border-indigo-500 text-white p-2 border-l-2 border-r-2 border-t-2 border-b-2 flex items-center focus:outline-none rounded-t-lg'
                : 'block w-full p-2 border-l-2 border-r-2 border-t-2 border-b-2 flex items-center hover:bg-gray-200 focus:outline-none rounded-lg'
            }
            onClick={() => {
              const newAnswer = {
                ...answer,
                level: 1,
              };
              dispatch({
                type: 'changeUserAnswers',
                newUserAnswers: {
                  ...userAnswers,
                  [habit.id]: newAnswer,
                },
                user,
              });
            }}
          >
            {
              (
                !level || level === 1
              )
              && (
                <i className="inline-block text-white fas fa-arrow-circle-right mr-2 text-lg fa-fw" />
              )
            }
            {
              (
                level > 1
              )
              && (
                <i className="inline-block text-green-600 fas fa-check-circle mr-2 text-lg fa-fw" />
              )
            }
            { snippets['1405257'] }
            <i className="ml-2 fas fa-clipboard leading-none" />
          </button>

          {
            (
              !level || level === 1
            )
            && (
              <div className="p-5 border-l-2 border-r-2 border-b-2 border-indigo-500 rounded-b-lg">
                <Level1 habit={habit} />
              </div>
            )
          }
        </div>

        <div className="mt-1">
          <button
            type="button"
            className={
              level === 2
                ? 'block w-full bg-indigo-500 border-indigo-500 text-white p-2 border-l-2 border-r-2 border-t-2 border-b-2 flex items-center focus:outline-none rounded-t-lg'
                : 'block w-full p-2 border-l-2 border-r-2 border-t-2 border-b-2 flex items-center hover:bg-gray-200 focus:outline-none rounded-lg'
            }
            onClick={() => {
              const newAnswer = {
                ...answer,
                level: 2,
              };
              dispatch({
                type: 'changeUserAnswers',
                newUserAnswers: {
                  ...userAnswers,
                  [habit.id]: newAnswer,
                },
                user,
              });
            }}
          >
            {
              level < 2
              && (
                <i className="inline-block far fa-circle mr-2 text-lg fa-fw" />
              )
            }
            {
              level === 2
              && (
                <i className="inline-block text-white fas fa-arrow-circle-right mr-2 text-lg fa-fw" />
              )
            }
            {
              (
                level > 2
              )
              && (
                <i className="inline-block text-green-600 fas fa-check-circle mr-2 text-lg fa-fw" />
              )
            }
            { snippets['1405258'] }
            <i className="ml-2 fas fa-mountain leading-none" />
          </button>

          {
            level === 2
            && (
              <div className="p-5 border-l-2 border-r-2 border-b-2 border-indigo-500 rounded-b-lg">
                <Level2 habit={habit} />
              </div>
            )
          }
        </div>

        <div className="mt-1">
          <button
            type="button"
            className={
              level === 3
                ? 'block w-full bg-indigo-500 border-indigo-500 text-white p-2 border-l-2 border-r-2 border-t-2 flex items-center focus:outline-none rounded-lg'
                : 'block w-full p-2 border-l-2 border-r-2 border-t-2 border-b-2 flex items-center hover:bg-gray-200 focus:outline-none rounded-lg'
            }
            onClick={() => {
              const newAnswer = {
                ...answer,
                level: 3,
              };
              dispatch({
                type: 'changeUserAnswers',
                newUserAnswers: {
                  ...userAnswers,
                  [habit.id]: newAnswer,
                },
                user,
              });
            }}
          >
            {
              level < 3
              && (
                <i className="inline-block far fa-circle mr-2 text-lg fa-fw" />
              )
            }
            {
              level === 3
              && (
                <i className="inline-block text-white fas fa-check-circle mr-2 text-lg fa-fw" />
              )
            }
            { snippets['1405259'] }
            <i className="ml-2 fas fa-check leading-none" />
          </button>
        </div>

      </div>
    </>
  );

  /*
  return (
    <>
      <div className="mb-3">
        <div className="flex hidden">
          <div
            className={
              !level
              || level === 1
                ? 'flex-grow border-2 p-2 rounded-tl-lg border-b-2 text-sm bg-gray-200 font-bold'
                : 'flex-grow border-2 p-2 rounded-tl-lg border-b-2 text-sm font-bold'
            }
          >
            <div className="text-center">
              <i className="mr-2 fas fa-clipboard leading-none" />
              { snippets['1405257'] }
            </div>
          </div>
          <div
            className={
              !!level
              && level === 2
                ? 'flex-grow border-t-2 border-b-2 p-2 text-sm bg-gray-200 font-bold'
                : 'flex-grow border-t-2 border-b-2 p-2 text-sm'
            }
          >
            <div className="text-center">
              <i className="mr-2 fas fa-mountain leading-none" />
              { snippets['1405258'] }
            </div>
          </div>
          <div
            className={
              !!level
              && level === 3
                ? 'flex-grow border-2 rounded-tr-lg p-2 text-sm bg-gray-200 font-bold'
                : 'flex-grow border-2 rounded-tr-lg p-2 text-sm'
            }
          >
            <div className="text-center">
              <i className="mr-2 fas fa-check leading-none" />
              { snippets['1405259'] }
            </div>
          </div>
        </div>

        <div className="border-2 rounded-lg">
          {
            (
              !level
              || level === 1
            )
            && (
              <>
                <div
                  className={
                    !level
                    || level === 1
                      ? 'flex-grow p-2 px- border-b-2 text-sm bg-gray-200 font-bold'
                      : 'flex-grow p-2 px- border-b-2 text-sm font-bold'
                  }
                >
                  <div className="">
                    <i className="mr-2 fas fa-clipboard leading-none" />
                    { snippets['1405257'] }
                  </div>
                </div>
                <div className="p-5">
                  <Level1 habit={habit} />
                </div>
              </>
            )
          }

          {
            level
            && level === 2
            && <Level2 habit={habit} />
          }

          {
            level
            && level === 3
            && <Level3 habit={habit} />
          }
        </div>
      </div>
    </>
  );
  */
}


HabitJourney.propTypes = {
  habit: PropTypes.object.isRequired,
};

export default HabitJourney;
