import React from 'react';
import { Link } from 'react-router-dom';
import { login, logout, getCredentials } from '../../database/auth';
import { useState } from '../../State';


function Nav() {
  const [{
    locale,
    urlParams,
    snippets,
    clBlocks,
    user,
  }] = useState();
  const { cl, q, h } = urlParams || {};

  const currentLocalePath = {
    nl: '/nl',
    en: '/en',
  };
  if (cl) {
    currentLocalePath.nl = `/nl/cl/${cl}`;
    currentLocalePath.en = `/en/cl/${cl}`;
  }
  if (cl && q) {
    currentLocalePath.nl = `/nl/cl/${cl}/q/${q}`;
    currentLocalePath.en = `/en/cl/${cl}/q/${q}`;
  }
  if (cl && q && h) {
    currentLocalePath.nl = `/nl/cl/${cl}/q/${q}/t/${h}`;
    currentLocalePath.en = `/en/cl/${cl}/q/${q}/t/${h}`;
  }

  return (
    <>
      <div className="bg-white p-3 border-b-2">
        <div className="max-w-5xl mx-auto flex justify-between items-end">
          <h1 className="font-bold">Focus Academy Canvas</h1>
          <div className="hidden lg:flex">
            <Link
              className={
                !cl
                  ? 'mx-2 pb-1 text-sm leading-none border-b-2 border-indigo-400 cursor-default'
                  : 'mx-2 pb-1 text-sm leading-none border-b-2 border-transparent hover:border-indigo-200'
              }
              to={`/${locale}`}
            >
              Home
            </Link>
            {
              !!clBlocks
              && clBlocks.map((clBlock) => {
                const { id, title } = clBlock;
                return (
                  <Link
                    className={
                      cl && cl === id
                        ? 'mx-2 pb-1 text-sm leading-none border-b-2 border-indigo-400 cursor-default'
                        : 'mx-2 pb-1 text-sm leading-none border-b-2 border-transparent hover:border-indigo-200'
                    }
                    to={`/${locale}/cl/${id}`}
                    key={id}
                  >
                    { title }
                  </Link>
                );
              })
            }
          </div>
          <div>
            {
              !!user
              && !user.isAnonymous
              && (
                <button
                  type="button"
                  className="bg-gray-500 hover:bg-gray-600 text-white text-xs px-2 rounded focus:outline-none"
                  onClick={() => { logout(); }}
                >
                  logout
                </button>
              )
            }
            {
              (
                !user
                || (
                  !!user
                  && !!user.isAnonymous
                )
              )
              && (
                <button
                  type="button"
                  className="bg-indigo-500 hover:bg-indigo-600 text-white text-xs px-2 rounded focus:outline-none"
                  onClick={() => { login(); }}
                >
                  { snippets['1383950'] }
                </button>
              )
            }
            <Link
              className={
                locale === 'nl'
                  ? 'ml-2 text-center pb-1 text-sm leading-none border-b-2 border-indigo-400 cursor-default'
                  : 'ml-2 text-center pb-1 text-sm leading-none border-b-2 border-transparent hover:border-indigo-200'
              }
              to={currentLocalePath.nl}
            >
              nl
            </Link>
            <Link
              className={
                locale === 'en'
                  ? 'ml-1 text-center pb-1 text-sm leading-none border-b-2 border-indigo-400 cursor-default'
                  : 'ml-1 text-center pb-1 text-sm leading-none border-b-2 border-transparent hover:border-indigo-200'
              }
              to={currentLocalePath.en}
            >
              en
            </Link>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full z-50 lg:hidden p-3">
        <div className="flex justify-between bg-white p-3 rounded-lg shadow">
          <Link
            className={
              !cl
                ? 'text-center px-2 pb-1 mx-2 text-sm leading-none border-b-2 border-indigo-400 cursor-default'
                : 'text-center px-2 pb-1 mx-2 text-sm leading-none border-b-2 border-transparent hover:border-indigo-200'
            }
            to={`/${locale}`}
          >
            <i className="em em-house" />
          </Link>
          {
            !!clBlocks
            && clBlocks.map((clBlock) => {
              const { id, iconClass } = clBlock;
              return (
                <Link
                  className={
                    cl && cl === id
                      ? 'text-center px-2 pb-1 mx-2 text-sm leading-none border-b-2 border-indigo-400 cursor-default'
                      : 'text-center px-2 pb-1 mx-2 text-sm leading-none border-b-2 border-transparent hover:border-indigo-200'
                  }
                  to={`/${locale}/cl/${id}`}
                  key={id}
                >
                  <i className={iconClass} />
                </Link>
              );
            })
          }
        </div>
      </div>
    </>
  );
}

export default Nav;
