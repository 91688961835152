import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CL from './CL';
import Starred from './Starred';
import Graph from './Graph';
import Intake from '../Intake';
import { useState } from '../../State';


function Dashboard(props) {
  const {
    intake,
  } = props;

  const [{
    locale,
    urlParams,
    snippets,
  }] = useState();

  const { cl, h } = urlParams;

  return (
    <>
      <div className="px-5 p lg:p-0">

        {
          !cl
          && (
            <>
              <div className="lg:flex max-w-5xl mx-auto my-10">
                <Link
                  className="mb-2 items-center rounded-lg px-4 py-2 text-lg text-left leading-none bg-indigo-500 text-white hover:bg-indigo-600 focus:outline-none inline-block"
                  to={`/${locale}/intake`}
                >
                  { snippets['1383961'] }
                  <i className="ml-3 fas fa-arrow-right" />
                </Link>
                {
                  !!intake
                  && <Intake />
                }
              </div>
              <div className="lg:flex max-w-5xl mx-auto mt-10 lg:mb-10">
                <div className="flex-1 mb-10 lg:mb-0 lg:pr-5">
                  <Starred h={h} />
                </div>
                <div className="flex-1">
                  <Graph />
                </div>
              </div>
            </>
          )
        }

        {
          !!cl
          && (
            <div className="max-w-5xl mx-auto my-10">
              <CL />
            </div>
          )
        }
      </div>

      <div className="mt-32" />
    </>
  );
}

Dashboard.defaultProps = {
  intake: false,
};

Dashboard.propTypes = {
  intake: PropTypes.bool,
};

export default Dashboard;
