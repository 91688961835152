import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import IntroductionModal from './IntroductionModal';
import Question from './Question';
import Habit from './Habit';
import { useState } from '../../../State';


function CL() {
  const [{
    urlParams,
    snippets,
    clBlocks,
  }] = useState();

  const { cl } = urlParams;
  const clBlock = clBlocks.find(clContent => clContent.id === cl);

  if (!clBlock) {
    return (
      <div>No block</div>
    );
  }

  const {
    title,
    questions,
    habits,
    habitSentence,
    content,
    introductionVideo,
  } = clBlock;

  return (
    <div className="bg-white rounded-lg p-5">

      <h2 className="mb-3 flex items-center text-2xl font-bold">
        {
          cl === '1126930'
          && <i className="em em-fire mr-3 flex-no-shrink" />
        }
        {
          cl === '1132478'
          && <i className="em em-zap mr-3 flex-no-shrink" />
        }
        {
          cl === '1132686'
          && <i className="em em-thought_balloon mr-3 flex-no-shrink" />
        }
        {
          cl === '1132717'
          && <i className="em em-loudspeaker mr-3 flex-no-shrink" />
        }
        <span className="flex-grow">{ title }</span>
      </h2>


      <div className="lg:flex">
        <div className="flex-1 pr-2">
          <h2 className="mb-2 mt-5 font-bold text-xl">{ snippets['1384037'] }</h2>
          {
            !!questions
            && questions.length
            && questions.map(question => (
              <Question
                key={question.id}
                question={question}
              />
            ))
          }
        </div>

        <div className="flex-1 pl-2">
          <h2 className="mb-2 mt-5 font-bold text-xl">{ habitSentence }</h2>
          {
            !!habits
            && habits.length
            && habits.map(habit => (
              <Habit
                key={habit.id}
                habit={habit}
              />
            ))
          }
        </div>
      </div>

      <div className="mt-12 flex justify-center">
        <IntroductionModal introductionVideo={introductionVideo} />
      </div>

      <div className="mt-10 lg:px-10">
        <ReactMarkdown
          className="markdown article"
          source={content}
          escapeHtml={false}
          linkTarget="_blank"
        />
      </div>


      <pre className="hidden">{JSON.stringify(questions, null, 2) }</pre>
      <pre className="hidden">{JSON.stringify(habits, null, 2) }</pre>
    </div>
  );
}

export default CL;
