import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="max-w-md mx-auto mt-32 text-center">
      <h1 className="text-indigo-500 font-bold text-2xl mb-5">
        Focus Academy Canvas
      </h1>


      <h2 className="mt-10 text-red-600 font-bold">404 - page not found..</h2>

      <Link
        className="mx-auto inline-block mt-5 bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded focus:outline-none"
        to="/"
      >
        Go back to homepage
        <i className="ml-2 fas fa-arrow-right" />
      </Link>
    </div>
  );
}

export default NotFound;
