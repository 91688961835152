import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AnswerButtons from './AnswerButtons';
import { useState } from '../../../State';


function QuestionModal(props) {
  const {
    question,
  } = props;

  const [{
    locale,
    urlParams,
  }] = useState();

  const { cl } = urlParams;

  const {
    title,
  } = question;
  const [showModal, setShowModal] = React.useState(true);
  const modal = React.useRef();


  React.useEffect(() => {
    const handleClick = (e) => {
      if (modal.current.contains(e.target)) {
        return;
      }
      setShowModal(false);
    };

    if (showModal) {
      document.body.classList.add('overflow-hidden');
      document.addEventListener('mousedown', handleClick);
      document.addEventListener('touchend', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchend', handleClick);
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchend', handleClick);
    };
  }, [showModal]);

  // always show link button

  if (!showModal) {
    return (
      <Redirect to={`/${locale}/cl/${cl}`} />
    );
  }

  return (
    <div
      className="fixed inset-0 z-50 flex py-10 px-5 lg:px-0"
      style={{
        background: 'rgba(0,0,0,0.7)',
        overflowY: 'scroll',
        overflowScrolling: 'touch',
        WebkitOverflowScrolling: 'touch',
        transform: 'translate3d(0,0,0)',
        WebkitTransform: 'translate3d(0,0,0)',
      }}
    >
      <div
        ref={modal}
        className="relative p-5 bg-white w-full max-w-3xl m-auto flex-col flex rounded-lg shadow-lg"
      >
        <div className="flex items-start mb-5">
          <h2 className="font-bold text-2xl">{ title }</h2>
          <button
            type="button"
            className="ml-auto leading-none p-2 inline-block rounded-lg hover:bg-gray-300 focus:outline-none"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <i className="fas fa-times fa-fw" />
          </button>
        </div>
        <div>
          <h5 className="text-base mb-2 italic">{ question.question }</h5>
          <div>
            <AnswerButtons question={question} />
          </div>
        </div>
      </div>
    </div>
  );
}

QuestionModal.propTypes = {
  question: PropTypes.object.isRequired,
};

export default QuestionModal;
